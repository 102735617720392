body {
  font-family: 'Graphik Medium', sans-serif;
  background-color: #e6e6fa;
  color: #000;
  margin: 0;
  padding: 0;
}

.admin-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.admin-title {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: #9975A6;
}

.add-button {
  padding: 10px 20px;
  border: 2px solid #9975A6;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  background-color: #9975A6;
  color: #fff;
  transition: background-color 0.3s ease;
}

.add-button:hover {
  background-color: #865c91; /* Slightly darker shade for contrast */
}

.ingredients-table-container {
  width: 100%;
  overflow-x: auto; /* Allow horizontal scroll if needed */
}

.ingredients-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.ingredients-table th, .ingredients-table td {
  border: 1px solid #9975A6;
  padding: 10px;
  text-align: left;
  word-wrap: break-word; /* Allow word wrap */
}

.ingredients-table th {
  background-color: #9975A6;
  color: white;
}

.ingredients-table td {
  background-color: #fff;
}

.ingredients-table th:nth-child(2), .ingredients-table td:nth-child(2) {
  width: 40%; /* Ajusta el ancho de la columna de descripción */
}

.ingredients-table th:nth-child(3), .ingredients-table td:nth-child(3) {
  width: 10%; /* Ajusta el ancho de la columna de nivel de peligrosidad */
}

.harmful-level {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  border-radius: 4px;
}

.action-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-icon {
  cursor: pointer;
  color: #9975A6;
  font-size: 1.5em;
  margin-right: 10px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
  max-width: 90%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

label {
  display: block;
  margin: 10px 0 5px;
  color: #9975A6;
}

input, textarea, select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  background-color: #9975A6;
  color: #fff;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #865c91; /* Slightly darker shade for contrast */
}

.delete-button-modal {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  background-color: #d9534f; /* Tono más bajo para el botón de eliminar */
  color: #fff;
  transition: background-color 0.3s ease;
}

.delete-button-modal:hover {
  background-color: #c9302c; /* Slightly darker shade for contrast */
}

.cancel-button {
  padding: 10px 20px;
  border: 2px solid #9975A6;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  background-color: #fff;
  color: #9975A6;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #ddd;
}

/* Media queries para mejorar el diseño en dispositivos móviles */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .ingredients-table th, .ingredients-table td {
    padding: 8px;
    font-size: 12px;
  }

  .ingredients-table th:nth-child(3), .ingredients-table td:nth-child(3) {
    width: 5%; /* Ajusta el ancho de la columna de nivel de peligrosidad para dispositivos móviles */
  }

  .ingredients-table th:nth-child(4), .ingredients-table td:nth-child(4) {
    width: 5%; /* Ajusta el ancho de la columna de acciones para dispositivos móviles */
  }

  .ingredients-table-container {
    margin-bottom: 20px;
  }

  .add-button {
    margin-top: 10px;
  }
}

/* Toggle Switch Styles */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-switch {
  display: flex;
  justify-content: space-between;
  width: 200px;
  background-color: #f1f1f1;
  border-radius: 25px;
  padding: 5px;
  position: relative;
  cursor: pointer;
}

.toggle-switch input[type="radio"] {
  display: none;
}

.toggle-option {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.toggle-option.active {
  background-color: #6a1b9a;
  color: white; /* Asegura que el texto sea visible cuando está activo */
  z-index: 1;
}

.toggle-option.inactive {
  color: #6a1b9a;
}

.toggle-switch .slider {
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 50%;
  background-color: #6a1b9a;
  border-radius: 20px;
  transition: left 0.3s ease;
}

.toggle-switch input[type="radio"]:checked + .slider {
  left: 50%;
}

.toggle-switch input[type="radio"]:not(:checked) + .slider {
  left: 0;
}
