body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f4f4f9;
}

h1 {
  color: #333;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

nav ul li {
  display: inline;
}

a {
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}

textarea, .contentEditable {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.harmful {
  background-color: rgba(255, 0, 0, 0.3);
  
}

.medium-harmful {
  background-color: rgba(255, 255, 0, 0.3);
  
}

.non-harmful {
  background-color: rgba(0, 255, 0, 0.3);
  
}


.table-container {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}
.actions {
  display: flex;
  gap: 10px;
}

.actions button {
  background-color: transparent;
  color: #a5a5a5;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.actions button:hover {
  background-color: #c82333;
}
