body {
  font-family: 'Graphik Medium', sans-serif;
  background-color: #e6e6fa;
  color: #000;
  margin: 0;
  padding: 0;
}

.home-container {
  max-width: 50%;
  margin: 0 auto;
  padding: 40px;
  background-color: #f8f8f8;
  border-radius: 8px;
  text-align: center;
}

.home-title {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: #9975A6;
  margin-bottom: 20px;
}

.home-description {
  font-family: 'Graphik Medium', sans-serif;
  color: #000;
  margin-bottom: 20px;
  text-align: justify;
}

.content-editable {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  white-space: pre-wrap;
  margin-bottom: 20px;
  text-align: left;
}

.result-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #9975A6;
  border-radius: 4px;
  white-space: pre-wrap; /* Mantener espacios en blanco y saltos de línea */
  background-color: #fff;
  text-align: left;
  justify-content: left;
  align-items: flex-start; /* Cambiado de center a flex-start */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 50%; /* Ajusta el ancho al contenido */
  margin-left: auto; /* Añade auto-margen izquierdo */
  margin-right: auto; /* Añade auto-margen derecho */
}

.button {
  padding: 10px 20px;
  border: 2px solid #9975A6;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: #9975A6;
  color: #fff;
}

.submit-button:hover {
  background-color: #865c91; /* Slightly darker shade for contrast */
}

.reset-button {
  background-color: #fff;
  color: #9975A6;
}

.reset-button:hover {
  background-color: #ddd; /* Slightly darker shade for contrast */
}

/* Clases adicionales para resaltar */
.harmful {
  background-color: yellow;
}

.medium-harmful {
  background-color: orange;
}

.non-harmful {
  background-color: lightgreen;
}

/* Asegúrate de que las palabras resaltadas se mantengan en línea */
span.harmful, span.medium-harmful, span.non-harmful {
  display: inline;
}

.all-ingredients-list li{
  text-align: left; /* Alinear el texto a la izquierda */
  margin-top: 20px;
}
/* Estilos para el loader */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Asegura que ocupe toda la altura de la pantalla */
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #9975A6; /* Purple */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.content-editable.locked {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #9975A6;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.help-box {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.help-box h4 {
  margin-top: 0;
  color: #9975A6;
}

.help-box p {
  margin: 5px 0;
}

/* Media queries para mejorar el diseño en dispositivos móviles */
@media (max-width: 768px) {
  .button-group {
    flex-direction: column;
    width: 100%;
  }

  .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .home-container {
    padding: 20px;
    width: 90%;
  }

  .content-editable {
    height: 150px;
  }
  .home-container {
    max-width: 80%;
    margin: 0 auto;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 8px;
    text-align: center;
  }
}
