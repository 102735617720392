/* ToggleSwitch.css */
.toggle-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .toggle-button {
    border: 2px solid #ccc;
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    font-size: 16px;
    border-radius: 20px;
    margin: 0 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .toggle-button.active {
    background-color: #6a0dad;
    color: white;
  }
  
  .toggle-button:not(.active) {
    background-color: #f0f0f0;
    color: #333;
  }
  