.auth-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center; /* Asegura que el texto esté centrado */
}

.klaviyo-form-YmZqXW {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.klaviyo-form-YmZqXW input {
  width: 100%;
  margin-bottom: 10px;
}

.klaviyo-form-YmZqXW button {
  width: 100%;
}
