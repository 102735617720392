.content-editable {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  white-space: pre-wrap;
  word-wrap: break-word; /* Asegura que las palabras largas se ajusten */
  margin-bottom: 20px;
  text-align: left; /* Alinear el texto a la izquierda */
  overflow-y: auto; /* Agrega un scroll vertical si el contenido excede el área */
  box-sizing: border-box;
  background-color: #fff; /* Asegura un fondo blanco */
}

.result-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #9975A6;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word; /* Asegura que las palabras largas se ajusten */
  background-color: #fff;
  overflow-x: auto; /* Agrega un scroll horizontal si el contenido excede el área */
}

.harmful-ingredients-list {
  text-align: left; /* Alinear el texto a la izquierda */
  margin-top: 20px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border: 2px solid #9975A6;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: #9975A6;
  color: #fff;
}

.submit-button:hover {
  background-color: #865c91; /* Slightly darker shade for contrast */
}

.reset-button {
  background-color: #fff;
  color: #9975A6;
}

.reset-button:hover {
  background-color: #ddd; /* Slightly darker shade for contrast */
}

.ingredient-highlighter {
  position: relative;
}

.editable-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  overflow-y: auto; /* Agrega un scroll vertical si el contenido excede el área */
}

.placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #aaa;
  pointer-events: none;
  font-size: 16px;
  padding: 8px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #9975A6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.result-container {
  margin-top: 20px;
}

.all-ingredients-list {
  margin-top: 20px;
}

.help-box {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  .home-title {
    font-size: 20px;
  }

  .home-description {
    font-size: 14px;
  }

  .button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    margin-bottom: 10px;
  }

  .result-container,
  .editable-container {
    font-size: 14px;
  }
}
