.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  position: relative;
  min-height: 100vh;
  padding-top: 80px; /* Espacio para el "header" */
}

.menu-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.admin-title {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.5em;
  color: #9975A6;
}

.menu-button {
  background: none;
  border: none;
  font-size: 36px; /* Aumentar el tamaño del icono */
  cursor: pointer;
  color: #9975A6; /* Cambiar el color del icono */
}

.menu-button:focus {
  outline: none;
}

.menu-button:hover {
  background: #f0f0f0;
}

.main-content {
  padding: 20px;
  padding-top: 50px;
}

.page-enter {
  opacity: 0;
  transform: scale(0.95);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 300ms, transform 300ms;
}

/* Estilos para las páginas y sus componentes */
.home-container,
.admin-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.home-title,
.admin-title {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: bold;
  font-size: 2em;
  color: #9975A6;
  margin-bottom: 20px;
}

.home-description,
.admin-description {
  font-family: 'Graphik Medium', sans-serif;
  color: #000;
  margin-bottom: 20px;
  text-align: justify;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.button {
  padding: 10px 20px;
  border: 2px solid #9975A6;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Red Hat Display', sans-serif;
  transition: background-color 0.3s ease;
}

.submit-button {
  background-color: #9975A6;
  color: #fff;
}

.submit-button:hover {
  background-color: #865c91;
}

.reset-button {
  background-color: #fff;
  color: #9975A6;
}

.reset-button:hover {
  background-color: #ddd;
}

/* Otros estilos */
.content-editable {
  width: 100%;
  height: 200px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  white-space: pre-wrap;
  margin-bottom: 20px;
  text-align: left;
}

.result-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #9975A6;
  border-radius: 4px;
  white-space: pre-wrap;
  background-color: #fff;
  text-align: left;
  justify-content: left;
  align-items: flex-start;
}

.harmful-ingredients-list h3 {
  margin-top: 20px;
}
