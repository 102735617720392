.dropdown-menu {
    position: absolute;
    right: 10px;
    top: 50px;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    z-index: 1000;
  }
  
  .dropdown-menu a,
  .dropdown-menu button {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
  }
  
  .dropdown-menu a:hover,
  .dropdown-menu button:hover {
    background: #f0f0f0;
  }
  