.password-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
  }
  
  .password-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 2px solid #9975A6;
  }
  
  .password-form h2 {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: bold;
    color: #9975A6;
    margin-bottom: 20px;
  }
  
  .password-form .error {
    color: red;
    margin-bottom: 10px;
    font-family: 'Graphik', sans-serif;
  }
  
  .password-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Graphik', sans-serif;
  }
  
  .password-form .password-button {
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    color: #9975A6;
    border: 2px solid #9975A6;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Red Hat Display', sans-serif;
  }
  
  .password-form .password-button:hover {
    background-color: #9975A6;
    color: #ffffff;
  }
  